import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { navigate } from 'gatsby';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import InputBase from '@material-ui/core/InputBase';
import Slider from '@material-ui/core/Slider';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import { register_user, login_user } from 'helpers/backend';

const CssTextField = withStyles({
  root: {
    width: '100%',
    color: '#42413D',
    '& label.Mui-focused': {
      color: '#42413d5c',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderWidth: '2px',
        borderColor: '#42413D',
      },
      '&:hover fieldset': {
        borderColor: '#42413d5c',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#42413d5c',
      },
      '& input:valid + fieldset': {
        borderColor: '#039869',
        borderWidth: 2,
      },
    },
  },
})(TextField);

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: '4px',
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    border: '2px solid #42413D',
    padding: '16px 14px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#42413D',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const marks = [
  {
    value: 5000,
    label: '£5000',
  },
  {
    value: 25000,
    label: '£25,000',
  },
  {
    value: 50000,
    label: '£50,000',
  },
  {
    value: 75000,
    label: '£75,000',
  },
  {
    value: 100000,
    label: '£100,000+',
  },
];

const ageMarks = [
  {
    value: 2000,
    label: 2000,
  },
  {
    value: 2019,
    label: 2019,
  },
];

const smlText = {
  fontSize: '0.8rem',
};

function valuetext(value) {
  return `${value}`;
}

function valueLabelFormat() {
  return '£';
}

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      makeoptions: [],
      makes: [],
      showMakes: false,
      carValue: '25000',
      ageRange: [2000, 2019],
      email: '',
      password: '',
      showPassword: false,
      submitting: false,
      checkedTerms: false,
    };
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.slideChangeValue = this.slideChangeValue.bind(this);
    this.ageSlideChangeValue = this.ageSlideChangeValue.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleMouseDownPassword - this.handleMouseDownPassword.bind(this);
    this.returnMakes = this.returnMakes.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTermsChange = this.handleTermsChange.bind(this);
  }

  componentDidMount() {
    if (window.history.state) {
      if (window.history.state.email) {
        this.setState({
          email: window.history.state.email,
        });
      }
    }
  }

  handleTermsChange() {
    this.setState({
      checkedTerms: !this.state.checkedTerms,
    });
  }

  onBlur(e) {
    if (!e.target.value && e.target.required) {
      this.setState({
        [e.target.name + '_error']: true,
      });
    }
    if (e.target.value) {
      this.setState({
        [e.target.name + '_error']: false,
      });
    }
    if (e.target.name == 'email' && e.target.value) {
      this.setState({
        email_exists: '',
        email_invalid: '',
      });
      const emailValid = e.target.value.match(
        /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
      );
      if (!emailValid) {
        this.setState({
          [e.target.name + '_error']: true,
          email_invalid: 'Please enter a valid email address',
        });
      }
      if (emailValid) {
        var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${process.env.BACKEND_PASSWORD}`;
        var bace64 = btoa(basicAuthCredential);
        axios
          .get(
            `${process.env.BACKEND_URL}/user/email/validation?mail=${e.target.value}&_format=hal_json`,
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/hal+json',
                'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
                Authorization: 'Basic ' + bace64,
              },
            }
          )
          .then(res => {
            if (res.data.length > 0) {
              this.setState({
                email_error: true,
                email_exists:
                  'Email address already exists, please login or use a different email address',
              });
            } else {
              this.setState({
                email_exists: '',
                email_invalid: '',
              });
            }
          });
      }
    }
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.name == 'makeoptions') {
      this.setState({
        showMakes: e.target.value == 'specific' ? true : false,
      });
      if (e.target.value == 'all') {
        this.setState({
          makes: [],
        });
      }
    }
  }

  slideChangeValue(e, value) {
    this.setState({
      carValue: value,
    });
  }

  ageSlideChangeValue(e, value) {
    this.setState({
      ageRange: value,
    });
  }

  handleClickShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  returnMakes(CarMakesDisplay, selected) {
    var retArray = new Array();
    {
      selected.map(id => (retArray[id] = CarMakesDisplay[id].name + ', '));
    }
    return retArray;
  }

  handleMouseDownPassword(e) {
    e.preventDefault();
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      submitting: true,
    });
    const data = this.state;
    register_user(data).then(res => {
      login_user(res.data.name[0].value, this.state.password).then(res => {
        let appState = {
          csrf: res.data.csrf_token,
          logout_token: res.data.logout_token,
          uid: res.data.current_user.uid,
          email: btoa(this.state.email),
          fnt: btoa(this.state.first_name),
          lnt: btoa(this.state.last_name),
          tl: btoa(this.state.telephone),
        };
        localStorage['appState'] = JSON.stringify(appState);
        this.props.toggleLoginStatus();

        const enquiry = {
          dealership_name: this.state.dealership_name,
          first_name: this.state.first_name,
          buyer_name: this.state.first_name + ' ' + this.state.last_name,
          email: this.state.email,
          telephone: this.state.telephone,
        };
        let uri = `${process.env.FE_RESOURCE_URL}/dealerregister`;
        axios
          .post(uri, enquiry)
          .then(response => {
            setTimeout(() => {
              this.setState({
                submitting: false,
              });
              navigate('/listcar');
            }, 1000);
          })
          .catch(error => {
            this.setState({
              submitting: false,
            });
            navigate('/listcar');
          });
      });
    });
  }

  render() {
    const CarMakesdata = this.props.data.allTaxonomyTermMake.edges;
    const onBlur = this.onBlur;
    const handleSubmit = this.handleSubmit;
    const onChange = this.onChange;
    const handleClickShowPassword = this.handleClickShowPassword;
    const slideChangeValue = this.slideChangeValue;
    const handleMouseDownPassword = this.handleMouseDownPassword;
    const ageSlideChangeValue = this.ageSlideChangeValue;
    const returnMakes = this.returnMakes;
    const handleTermsChange = this.handleTermsChange;
    const {
      makeoptions,
      makes,
      showMakes,
      ageRange,
      showPassword,
      password,
      email_exists,
      email_invalid,
      submitting,
      checkedTerms,
    } = this.state;

    const CarMakesDisplay = new Array();
    {
      CarMakesdata.map(
        name =>
          (CarMakesDisplay[name.node.drupal_internal__tid] = {
            name: name.node.name,
          })
      );
    }
    return (
      <div className="row pt-5 position-relative">
        {submitting && (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              background: '#f3f3f3cf',
              zIndex: '10',
            }}
            className="overlay d-flex align-items-center justify-content-center"
          >
            <CircularProgress />
          </div>
        )}
        <div className="col-12 col-md-8 col-lg-5 offset-0 offset-md-2 m-lg-auto">
          <form onSubmit={handleSubmit}>
            <h2>Your details</h2>
            <CssTextField
              label="First Name"
              name="first_name"
              margin="normal"
              variant="outlined"
              required
              error={this.state.first_name_error}
              onChange={onChange}
              onBlur={onBlur}
            />
            <CssTextField
              label="Last Name"
              name="last_name"
              margin="normal"
              variant="outlined"
              required
              error={this.state.last_name_error}
              onChange={onChange}
              onBlur={onBlur}
            />
            <CssTextField
              label="Email"
              name="email"
              type="email"
              margin="normal"
              variant="outlined"
              required
              error={this.state.email_error}
              onChange={onChange}
              onBlur={onBlur}
              value={this.state.email}
            />
            {email_exists && (
              <p
                style={{ fontSize: '0.8rem' }}
                className="mt-1 mb-0 px-3 font-weight-bold text-danger"
              >
                {email_exists}
              </p>
            )}
            {email_invalid && (
              <p
                style={{ fontSize: '0.8rem' }}
                className="mt-1 mb-0 px-3 font-weight-bold text-danger"
              >
                {email_invalid}
              </p>
            )}
            <CssTextField
              id="outlined-adornment-password"
              variant="outlined"
              margin="normal"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              name="password"
              value={password}
              onChange={onChange}
              onBlur={onBlur}
              error={this.state.password_error}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <p
              style={{ fontSize: '0.8rem' }}
              className="mt-1 mb-0 px-3 font-weight-bold"
            >
              Please set a password to create your Sell it on for dealers
              account
            </p>
            <CssTextField
              label="Telephone"
              name="telephone"
              type="number"
              margin="normal"
              variant="outlined"
              required
              error={this.state.telephone_error}
              onChange={onChange}
              onBlur={onBlur}
            />
            <h2 className="mt-4">Dealership</h2>
            <CssTextField
              label="Dealership Name"
              name="dealership_name"
              margin="normal"
              variant="outlined"
              required
              error={this.state.dealership_name_error}
              onChange={onChange}
              onBlur={onBlur}
            />
            <CssTextField
              label="Dealership Postcode"
              name="dealership_postcode"
              margin="normal"
              variant="outlined"
              required
              error={this.state.dealership_postcode_error}
              onChange={onChange}
              onBlur={onBlur}
            />
            <CssTextField
              label="Website"
              name="dealership_website"
              margin="normal"
              variant="outlined"
              required
              error={this.state.dealership_website_error}
              onChange={onChange}
              onBlur={onBlur}
            />
            <h2 className="mt-4">Buying preferences</h2>
            <p className="font-weight-bold">
              Which car makes do you look to purchase
            </p>
            <RadioGroup
              aria-label="makeoptions"
              name="makeoptions"
              value={makeoptions}
              onChange={onChange}
            >
              <FormControlLabel
                value="all"
                control={<Radio />}
                label="All brands"
              />
              <FormControlLabel
                value="specific"
                control={<Radio />}
                label="Specific brands"
              />
            </RadioGroup>
            {showMakes && (
              <FormControl className="mb-4">
                <p className="mb-1">Select Brands</p>
                <Select
                  multiple
                  name="makes"
                  value={makes}
                  onChange={onChange}
                  input={<BootstrapInput id="outlined-car-brands" />}
                  renderValue={selected =>
                    returnMakes(CarMakesDisplay, selected)
                  }
                  MenuProps={MenuProps}
                >
                  {CarMakesdata.map(name => (
                    <MenuItem
                      key={name.node.drupal_internal__tid}
                      value={name.node.drupal_internal__tid}
                    >
                      <Checkbox
                        checked={
                          makes.indexOf(name.node.drupal_internal__tid) > -1
                        }
                      />
                      <ListItemText primary={name.node.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <p className="mt-4 mb-4 font-weight-bold">
              Up to what value do you typically purchase?
            </p>
            <Slider
              defaultValue={50000}
              valueLabelFormat={valueLabelFormat}
              getAriaValueText={valuetext}
              aria-labelledby="discrete-slider-restrict"
              step={null}
              valueLabelDisplay="auto"
              min={5000}
              max={100000}
              marks={marks}
              onChange={slideChangeValue}
            />

            <p className="mt-4 mb-4 font-weight-bold">
              What age range do you typically purchase?
            </p>
            <Slider
              value={ageRange}
              onChange={ageSlideChangeValue}
              min={2000}
              max={2019}
              marks={ageMarks}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              getAriaValueText={valuetext}
            />
            <FormControlLabel
              className="mt-5"
              control={
                <Switch
                  checked={checkedTerms}
                  onChange={handleTermsChange}
                  value="terms"
                  color="primary"
                />
              }
              label="I have read and and accept the Terms & Conditions"
            />
            {!checkedTerms && (
              <div>
                <p
                  style={smlText}
                  className="font-weight-bold text-danger mb-0 mt-4"
                >
                  Please read and agree to our terms and conditions before
                  submitting your details. By agreeing to our{' '}
                  <a href="/terms" target="_blank">
                    terms & conditions
                  </a>{' '}
                  and submitting this form you consent to Sell it on Ltd storing
                  your personal details and using them to contact you regarding
                  your enquiry and further information based on your preference
                  settings. Personal details will never be shared with a third
                  party and you can opt out anytime by contacting us by email or
                  telephone{' '}
                </p>
              </div>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="w-100 mt-5"
              disabled={submitting || email_exists || !checkedTerms}
            >
              {submitting ? <CircularProgress /> : 'Register'}
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Register;
