import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Consumer } from 'store/createContext';
import Layout from 'components/layout';
import Register from 'components/register';

class Signup extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;
    return (
      <Consumer>
        {({ toggleLoginStatus }) => (
          <Layout>
            <div className="container mt-5 mb-5">
              <div className="row">
                <div className="col-12 text-center">
                  <h1>
                    Register with Sell it on <strong>Dealers</strong>
                  </h1>
                </div>
                <div className="col-12 col-lg-8 offset-0 offset-lg-2 text-center mt-4">
                  <p>
                    To join the hundreds of UK dealers currently using Sell It
                    On and to immediately start receiving vehicles for sale
                    simply fill in the contact form below
                  </p>
                </div>
              </div>
              <Register data={data} toggleLoginStatus={toggleLoginStatus} />
            </div>
          </Layout>
        )}
      </Consumer>
    );
  }
}

export default Signup;

Signup.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    allTaxonomyTermMake {
      totalCount
      edges {
        node {
          relationships {
            node__lead {
              drupal_internal__nid
            }
          }
          drupal_internal__tid
          name
        }
      }
    }
  }
`;
